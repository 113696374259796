<template>
  <div>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Reports</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-tabs>

        <v-tab dark>Documents Reports</v-tab>

        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">

<!--              <v-col cols="6">-->
<!--                <v-btn-->
<!--                  class="mr-1"-->
<!--                  outlined-->
<!--                  @click="exportSapDocuments"-->
<!--                  color="toolbarIcon"-->
<!--                  target="_blank"-->
<!--                >-->
<!--                  <v-icon-->
<!--                    left-->
<!--                    dark-->
<!--                  >mdi-plus</v-icon>Export Documents Synced From SAP-->
<!--                </v-btn>-->
<!--              </v-col>-->

              <v-col cols="6">
                <v-btn
                    class="mr-1"
                    outlined
                    @click="exportScanLogs"
                    id="export-scan-logs"
                    color="toolbarIcon"
                    target="_blank"
                >
                  <v-icon
                      left
                      dark
                  >mdi-plus</v-icon>Export Scan Logs
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </div>
</template>


<script>
export default {
  data: () => ({
    recordData: {},
    loader: false,
    numberingData: [],
    openDocuments: [],
    openSyncedDialog: false,
    NotifAlert: false,
    glTypes: [
      { name: "Item Group", value: "C" },
      { name: "Item Level", value: "L" },
      { name: "Warehouse", value: "W" },
    ],
    headers: [
      { text: "Document", value: "ObjType" },
      { text: "DocNum", value: "ExtRefDocNum" },
    ],
  }),
  methods: {
    exportScanLogs() {
      this.$store
          .dispatch("get", `/export-scan-logs`)
          .then((res) => {
            this.$refs.snackbar.show(res.message, "green");
          })
          .catch((err) => {
            console.log(err);
          });
    },
    exportSapDocuments() {
      this.$store
          .dispatch("get", `/export-sap-documents`)
          .then((res) => {
            this.$refs.snackbar.show(res.message, "green");
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  created() {
    // this.exportScanLogs();
  },
};
</script>