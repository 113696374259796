<template>
  <v-container fluid>
    <!-- start of editor -->
    <editor></editor>
    <!-- end of editor -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
  <!-- snackbar -->
</template>

<script>
import Editor from "./_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    search: "",
  }),
  methods: {},
  created() {},
};
</script>